<template>
  <div>
    <div class="main">
      <router-view></router-view>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" @click="toPage('/')">首页</van-tabbar-item>
      <van-tabbar-item icon="balance-list-o" @click="toPage('/list')">缴费</van-tabbar-item>
      <van-tabbar-item icon="friends-o" @click="toPage('/mine')">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0
    }
  },
  methods: {
    toPage(url) {
      this.$router.push(url)
    }
  }
};
</script>
<style  lang='scss'>
.main {
  padding-bottom: 60px;
}
</style>