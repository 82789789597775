import './utils/reset.scss'
import '@pasc/scui/lib/index.css'
import '@pasc/scui/lib/index.js'
import 'vant/lib/index.css';
import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import http from 'axios'
import {
	Toast
} from 'vant';
Vue.prototype.$message = Toast;
Vue.config.productionTip = false
// http.defaults.baseURL = 'http://api-water.jsyqyf.cn'
// http.defaults.baseURL = 'http://112.24.98.180:8077'
// http.defaults.baseURL = 'http://127.0.0.1:8077'
http.defaults.baseURL = 'http://dc.jsyqyf.cn'
//http.defaults.baseURL = 'http://218.92.211.106:8077'
Vue.prototype.$http = http

Vue.use(Vant)

new Vue({
	render: h => h(App),
	router
}).$mount('#app')