

import Vue from 'vue';
import VueRouter from 'vue-router';

const index = () => import('../page/index')
const error = () => import('../page/error')
const success = () => import('../page/success')
const result = () => import('../page/result')
const loginPassword = () => import('../page/login-password')
const loginCode = () => import('../page/login-code')
const list = () => import('../page/list')
const payList = () => import('../page/payList')
const bill = () => import('../page/bill')
const mine = () => import('../page/mine')
const suggest = () => import('../page/suggest')
const infoChange = () => import('../page/info-change')
const hasPayment = () => import('../page/hasPayment.vue')
Vue.use(VueRouter);
const routes = [
	{
		path: '/',
		component: index,
	},
	{
		path: '/error',
		component: error
	},
	{
		path: '/success',
		component: success
	},
	{
		path: '/result',
		component: result
	},
	{
		path: '/login1',
		component: loginPassword
	},
	{
		path: '/login2',
		component: loginCode
	},
	{
		path: '/list',
		component: list
	},
	{
		path: '/payList',
		component: payList
	},
	{
		path: '/bill',
		component: bill
	},
	{
		path: '/mine',
		component: mine
	},
	{
		path: '/suggest',
		component: suggest
	},
	{
		path: '/infoChange',
		component: infoChange
	},
	{
		path: '/hasPayment',
		component: hasPayment
	}
]

const router = new VueRouter({
    mode: 'hash',
    routes,
});

// router.beforeEach((to, from, next) => {
// 	/* 路由发生变化修改页面 title */
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}
// 	next()
// })

export default router;
